import 'is-in-viewport';

$( function() {
	animateInViewport();
} );

let scrollInterval = null;
let scrollTimeout = null;
$( window ).scroll( function() {
	clearTimeout( scrollTimeout );
	if ( scrollInterval === null ) {
		scrollInterval = setInterval( () => {
			animateInViewport();
		}, 50 );
	}
	scrollTimeout = setTimeout( () => {
		clearInterval( scrollInterval );
		scrollInterval = null;
	}, 50 );
} );

export function animateInViewport() {
	$( '.move-up-in-viewport, .move-down-in-viewport, .move-left-in-viewport, .move-right-in-viewport, .fade-in-in-viewport, .zoom-in-in-viewport, .active-in-viewport, .full-width-in-viewport' ).filter( ':not(.wait-to-animate)' ).each( function() {
		var $this = $( this );

		if ( $this.is( ':in-viewport' ) ) {
			$this.addClass( 'active' );
		}
	} );
}
